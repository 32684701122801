import {
    SaveButton,
    SimpleForm,
    useNotify, useRecordContext,
    useRefresh,
} from "react-admin";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {ActionConfirmEnableZapier, ActionPetitionEnableZapier} from "./EnableDisableZapier";
import AccountCircle from '@mui/icons-material/AccountCircle';
import DialogActions from "@mui/material/DialogActions";
import * as PropTypes from "prop-types";
import WarningIcon from '@mui/icons-material/Warning';

WarningIcon.propTypes = {sx: PropTypes.shape({color: PropTypes.string, marginRight: PropTypes.string})};
export const UserEditDialog = (props) => {
    const { action } = props;

    const [open, setOpen] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const record = useRecordContext();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async (data) => {
        let actionResponse;

        if (action === 'enableZapier') {
            actionResponse = await ActionConfirmEnableZapier(data);
        }

        let statusAction = (actionResponse.error === false) ? 'success' : 'error';
        let messageAction = actionResponse.message;

        refresh();
        notify(`${messageAction}`, {type: `${statusAction}`});

        setOpen(false);
    };

    let buttonText = '';
    let formPetition = '';

    let color = "success";
    if (action === 'enableZapier') {
        color = record && record.zapier ? "success" : "error";
        buttonText = record && record.zapier ? "Zapier Activado" : "Zapier Desactivado";
        formPetition = <ActionPetitionEnableZapier/>;
    }

    return (
        <>
            <Button
                label="Deshabilitar usuario"
                onClick={handleClick}
                sx={{
                    backgroundColor: color === 'error' ? '#d32f2f' : '#2e7d32',
                    color: '#fff',
                    fontWeight: 'bold',
                    borderRadius: '4px',
                    '&:hover': {
                        backgroundColor: color === 'error' ? '#aa2424' : '#1b5e20',
                    },
                }}
            >
                {buttonText}
            </Button>

            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        borderRadius: '8px',
                        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        backgroundColor: '#f5f5f5',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '16px 24px',
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <WarningIcon sx={{ color: '#f57c00', marginRight: '12px' }} />
                    {"¿Seguro desea realizar esta acción?"}
                </DialogTitle>
                <DialogContent sx={{ padding: '24px', paddingBottom: '16px' }}>
                    <SimpleForm
                        form="post-quick-create"
                        resource="put"
                        onSubmit={handleConfirm}
                        toolbar={false}
                        defaultValues={record}
                    >
                        {formPetition}

                        <DialogActions sx={{
                            padding: '16px 0 0 0',
                            marginTop: '24px',
                            borderTop: '1px solid #e0e0e0',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                onClick={handleDialogClose}
                                sx={{
                                    color: '#616161',
                                    '&:hover': { backgroundColor: '#f5f5f5' }
                                }}
                            >
                                Cancelar
                            </Button>
                            <SaveButton
                                label="Actualizar"
                                sx={{
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    fontWeight: 500,
                                    '&:hover': { backgroundColor: '#1565c0' }
                                }}
                            />
                        </DialogActions>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};