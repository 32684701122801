import {
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    ShowButton,
    EditButton,
    useRecordContext,
    TopToolbar,
    CreateButton,
    ReferenceInput,
    SelectInput,
    Create,
    TextInput,
    Edit,
    SimpleForm,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    AutocompleteInput,
    Labeled,
    ReferenceArrayField,
    useNotify, FunctionField, DateInput, Filter, List, useRedirect,
} from 'react-admin';
import {useMediaQuery, Stack} from '@mui/material';
import React from "react";
import Button from "@mui/material/Button";
import {LoadData} from "./Utils/Services/Edit_services";
import {CopyData} from './App';
import {useState, useCallback} from 'react';
import {apiClient} from './Utils/Services/fetchService';
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import Chip from "@mui/material/Chip";
import * as PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {UserEditDialog} from "./Utils/Dialogs/UserEditDialog";

const BillingAccountCreate = ({open, onClose, clientId, onSuccess}) => {
    const [formData, setFormData] = useState({
        companyName: '',
        cif: '',
        fiscalAddress: '',
        contactPhone: '',
        contactEmail: '',
        contactName: '',
    });
    const notify = useNotify();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSave = async () => {

        try {
            const response = await apiClient.post(`${process.env.REACT_APP_API_URL}lt/clientes/billing/${clientId}/billing-accounts-info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    clienteId: clientId
                })
            });

            if (!response.ok) {
                throw new Error('Error al crear la cuenta de facturación');
            }

            const data = await response.json();
            notify('Cuenta de facturación creada con éxito', {type: 'success'});
            onSuccess(data);
            onClose();
        } catch (error) {
            console.error('Error:', error);
            notify('Error al crear la cuenta de facturación', {type: 'error'});
        }
    };

    if (!open) return null;

    return (
        <div className="modal-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div className="modal-content" style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                width: '500px',
                maxWidth: '90%',
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <div className="modal-header" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px'
                }}>
                    <h2 style={{margin: 0}}>Crear Nueva Cuenta de Facturación</h2>
                    <button
                        onClick={onClose}
                        style={{
                            background: 'none',
                            border: 'none',
                            fontSize: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        ×
                    </button>
                </div>

                <div>
                    <div style={{marginBottom: '15px'}}>
                        <label style={{display: 'block', marginBottom: '5px'}}>
                            Nombre de la Empresa *
                        </label>
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd'
                            }}
                        />
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        <label style={{display: 'block', marginBottom: '5px'}}>
                            CIF *
                        </label>
                        <input
                            type="text"
                            name="cif"
                            value={formData.cif}
                            onChange={handleChange}
                            required
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd'
                            }}
                        />
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        <label style={{display: 'block', marginBottom: '5px'}}>
                            Dirección Fiscal
                        </label>
                        <input
                            type="text"
                            name="fiscalAddress"
                            value={formData.fiscalAddress}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd'
                            }}
                        />
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        <label style={{display: 'block', marginBottom: '5px'}}>
                            Teléfono de Contacto
                        </label>
                        <input
                            type="tel"
                            name="contactPhone"
                            value={formData.contactPhone}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd'
                            }}
                        />
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        <label style={{display: 'block', marginBottom: '5px'}}>
                            Email de Contacto
                        </label>
                        <input
                            type="email"
                            name="contactEmail"
                            value={formData.contactEmail}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd'
                            }}
                        />
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        <label style={{display: 'block', marginBottom: '5px'}}>
                            Nombre de Contacto
                        </label>
                        <input
                            type="text"
                            name="contactName"
                            value={formData.contactName}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd'
                            }}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px',
                        marginTop: '20px'
                    }}>
                        <button
                            type="button"
                            onClick={onClose}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '4px',
                                border: '1px solid #ddd',
                                background: 'white',
                                cursor: 'pointer'
                            }}
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            onClick={handleSave}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '4px',
                                border: 'none',
                                background: '#1976d2',
                                color: 'white',
                                cursor: 'pointer'
                            }}
                        >
                            Crear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const UsersFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn/>

        <ReferenceInput source='id_clientes' reference='clientes' allowEmpty perPage={-1}
                        sort={{field: 'nombre', order: 'ASC'}} alwaysOn>
            <SelectInput label="Cliente" optionText="nombre"/>
        </ReferenceInput>

        <DateInput label="Fecha de alta" source="fecha_registro" alwaysOn/>

        <UserReportButton/>


    </Filter>
);


const UsersListToolbar = () => (
    <Stack direction="row" spacing={2} alignItems="center">
        <UserReportButton size="small"/>
        <CreateButton size="small" startIcon={null}/>
    </Stack>
);

const UsersShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

export const UsersShow = () => (
    <Show actions={<UsersShowActions/>}>
        <SimpleShowLayout>

            <TextField source="nombreApellidos" label="Nombre y apellidos"/>
            <TextField source="email"/>

            <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                <FunctionField render={(record) => (
                    <ChipField source="nombre"
                               color={record.activo ? 'success' : 'error'}
                               icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                )}/>
            </ReferenceField>

            <ReferenceArrayField label='Concesionarios' reference='concesionarios' source='concesionarios'>
                <SingleFieldList linkType="show">
                    <FunctionField render={(record) => {
                        if (!record) return null;
                        const marcas = record.marcas ? ` (${record.marcas})` : '';
                        const label = `${record.nombre}${marcas}`;
                        return (
                            <Chip
                                label={label}
                                variant="outlined"
                                color={record.activo ? 'success' : 'error'}
                            />
                        );
                    }}/>
                </SingleFieldList>
            </ReferenceArrayField>

            <FunctionField
                label="Roles"
                render={record =>
                    record.roles.map(role => (
                        <Chip
                            key={role}
                            label={role}
                            style={{marginRight: '5px', marginBottom: '5px'}}
                        />
                    ))
                }
            />

            <TextField source="billingCif"/>
            <TextField source="billingCompanyName"/>
            <TextField source="fechaAlta"/>
            <TextField source="fechaModificacion"/>
            <TextField source="fechaBaja"/>

            <TextField source="cargo"/>
            <TextField source="telefono"/>
            <BooleanField source="enabled" label="Activo/Inactivo"/>
            <TextField source="lastLogin" label="Último acceso" showTime/>
            <TextField source="token"/>

            <TextField source="fueraOficina" label="Fuera de la oficina"/>
            <TextField source="fueraOficinaDesde" label="Fuera de la oficina (desde)"/>
            <TextField source="fueraOficinaHasta" label="Fuera de la oficina (hasta)"/>
            <TextField source="fueraOficinaBackup" label="Fuera de la oficina (backup)"/>


        </SimpleShowLayout>
    </Show>
);

export const UsersList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List filters={<UsersFilter/>} actions={<UsersListToolbar/>}>

            {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
            ) : (
                <Datagrid>
                    <div label="ID">
                        <TextField source="id" sortable={true}/>
                        <CopyData field="id"></CopyData>
                    </div>
                    <TextField source="nombreApellidos" label="Nombre y apellidos" sortable={false}/>

                    <div label="Email">
                        <TextField source="email"/>
                        <CopyData field="email"></CopyData>
                    </div>

                    <TextField source="fechaAlta" label="Fecha de alta"/>

                    <TextField source="lastLogin" label="Último acceso"/>

                    <FunctionField
                        label="Roles"
                        render={record =>
                            record.roles.map(role => (
                                <Chip
                                    key={role}
                                    label={role}
                                    style={{marginRight: '5px', marginBottom: '5px'}}
                                />
                            ))
                        }
                    />

                    <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                        <FunctionField render={(record) => (
                            <ChipField source="nombre"
                                       color={record.activo ? 'success' : 'error'}
                                       icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                        )}/>
                    </ReferenceField>

                    <ShowButton/>
                    <EditButton/>

                </Datagrid>
            )}
        </List>
    );

};

const roles = [
    {id: 'ROLE_USER', name: 'ROLE_USER'},
    {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
    {id: 'ROLE_LEAD_MANAGER', name: 'ROLE_LEAD_MANAGER'},
    {id: 'ROLE_GERENTE', name: 'ROLE_GERENTE'},
    {id: 'ROLE_JV_PLUS', name: 'ROLE_JV_PLUS'},
    {id: 'ROLE_JEFE_VENTAS', name: 'ROLE_JEFE_VENTAS'},
    {id: 'ROLE_CONTACT_CENTER', name: 'ROLE_CONTACT_CENTER'},
    {id: 'ROLE_CONTROL_CALIDAD', name: 'ROLE_CONTROL_CALIDAD'},
    {id: 'ROLE_COMERCIAL', name: 'ROLE_COMERCIAL'},
    {id: 'SERVICE_ZAPIER', name: 'SERVICE_ZAPIER'}
];

const updateUserPermissions = (id, cliente) => {

    const urlAPI = `${process.env.REACT_APP_API_URL}`;
    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : {};
    const username = userData.username || '';

    return apiClient.post(`${urlAPI}lt/configUserPermissions?idUser=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username})
    })
        .then(response => response.json())
        .then(data => {
            window.location.reload();
        });

};

const UpdateUserPermissionsButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (

        <Button
            label='Actualizar permisos por defecto'
            onClick={() => updateUserPermissions(record.id, record.cliente)}
            sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                fontWeight: 'bold',
                '&:hover': {
                    backgroundColor: 'primary.dark',
                },
            }}
        >
            Actualizar permisos por defecto
        </Button>

    );
}

export const UsersEdit = props => {

    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : {};
    let isDisabled = !(userData.roles.includes('ROLE_SUPPORT') || userData.roles.includes('ROLE_SUPER_ADMIN') || userData.roles.includes('ROLE_ADMIN_LEADTRACKING'));

    return (
        <Edit {...props}>
            <SimpleForm>

                <TextInput source="storedUsername" defaultValue={userData.username} style={{display: 'none'}}/>

                <TextInput label="ID" source="id" disabled/>

                <ReferenceInput label="Cliente" source="cliente" reference="clientes" allowEmpty perPage={-1}>
                    <AutocompleteInput optionText="nombre" sx={{width: '300px'}} disabled/>
                </ReferenceInput>

                <TextInput label="Nombre" source="nombre"/>

                <TextInput label="Apellidos" source="apellidos"/>

                <TextInput label="Email" source="email" type="email" disabled/>

                <BooleanInput source="enabled" label="Activo/Inactivo"/>

                <SelectArrayInput source="roles" label="Roles" choices={roles}/>

                <div style={{opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto'}}>
                    <Labeled label="Servicios de usuarios">
                        <ArrayInput label="" name="services" source="userServices">
                            <SimpleFormIterator
                                inline
                                disableAdd={true}
                                disableRemove={true}
                                disableReordering={true}
                                getItemLabel={() => ''}
                            >
                                <TextField source="title"/>
                                <BooleanInput source="active" helperText={false}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Labeled>
                </div>

                <div style={{opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto', marginBottom: '10px'}}>
                    <UpdateUserPermissionsButton/>
                </div>

                <div style={{
                    margin: 0,
                    padding: 0
                }}>
                    <UserEditDialog action="enableZapier"/>
                </div>

            </SimpleForm>

            <LoadData typeData="user"/>
        </Edit>
    );
};

function FormHelperText(props) {
    return null;
}

FormHelperText.propTypes = {
    style: PropTypes.shape({color: PropTypes.string, fontWeight: PropTypes.string}),
    children: PropTypes.node
};
export const UsersCreate = (props) => {
    const [billingAccounts, setBillingAccounts] = useState([]);
    const [selectedBillingId, setSelectedBillingId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);

    const loadBillingAccounts = useCallback(async (clientId) => {
        setBillingAccounts([]);
        setSelectedBillingId(null);

        if (!clientId) return;

        const urlAPI = `${process.env.REACT_APP_API_URL}lt`;

        try {
            const response = await apiClient.get(`${urlAPI}/clientes/billing/${clientId}/billing-accounts-info`);
            const responseData = await response.json();

            if (responseData?.data?.length > 0) {
                const validAccounts = responseData.data.filter(account =>
                    account && account.id && account.companyName && account.cif
                );
                setBillingAccounts(validAccounts);

                if (validAccounts.length > 0) {
                    setSelectedBillingId(validAccounts[0].id);
                }
            } else {
                setModalOpen(true);
            }
        } catch (error) {
            console.error('Error al cargar cuentas de facturación:', error);
            setBillingAccounts([]);
        }
    }, []);

    const handleClientChange = useCallback((value) => {
        setSelectedClientId(value);
        loadBillingAccounts(value);
    }, [loadBillingAccounts]);

    const handleBillingAccountChange = useCallback((value) => {
        setSelectedBillingId(value || null);
    }, []);

    const handleNewBillingSuccess = useCallback((newBillingAccount) => {
        loadBillingAccounts(selectedClientId);
    }, [selectedClientId, loadBillingAccounts]);

    return (
        <Create {...props}>
            <SimpleForm>

                <ReferenceInput
                    label="Cliente"
                    source="id_clientes"
                    reference="clientes"
                    allowEmpty
                    perPage={-1}
                >
                    <AutocompleteInput
                        label="Cliente"
                        optionText="nombre"
                        sx={{width: '300px'}}
                        onChange={handleClientChange}
                    />
                </ReferenceInput>

                <AutocompleteInput
                    source="id_cliente_billing"
                    choices={billingAccounts}
                    optionText={record => {
                        if (!record?.companyName || !record?.cif) return '';
                        return `${record.companyName} - ${record.cif}`;
                    }}
                    value={selectedBillingId}
                    onChange={handleBillingAccountChange}
                    optionValue="id"
                    label="Cuenta de Facturación"
                    disabled={billingAccounts.length === 0}
                    emptyText={
                        billingAccounts.length === 0
                            ? "Seleccione primero un cliente"
                            : "Seleccione una cuenta de facturación"
                    }
                    sx={{width: '300px'}}
                    inputText={record => {
                        const selected = billingAccounts.find(acc => acc.id === selectedBillingId);
                        if (!selected) return '';
                        return `${selected.companyName} - ${selected.cif}`;
                    }}
                    allowEmpty
                />

                <TextInput label="Nombre" source="name"/>
                <TextInput label="Apellidos" source="last_name"/>
                <TextInput label="Email" source="email" type="email"/>
                <TextInput label="Contraseña" source="password"/>
                <SelectArrayInput source="roles" label="Roles" choices={roles}/>

                <div>
                    <Typography variant="h6" color="textPrimary" style={{marginBottom: 4}}>
                        Permisos de usuario
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{color: 'orange', fontWeight: 'bold'}}>
                        Tras finalizar la creación, crea manualmente el servicio de configuración de permisos en la
                        edición del usuario de LeadTracking con el botón "Actualizar permisos por defecto".
                    </Typography>
                </div>


                <BillingAccountCreate
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    clientId={selectedClientId}
                    onSuccess={handleNewBillingSuccess}
                />
            </SimpleForm>
        </Create>
    );
};


const downloadUserReport = async () => {
    try {
        const response = await apiClient.post(`${process.env.REACT_APP_API_URL}lt/users/export`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
            }
        });

        if (!response.ok) {
            throw new Error('Error al descargar el informe');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;
        const fileDownloadName = `USERS - INFORME USUARIOS POR CLIENTE ${formattedDate}.xlsx`;
        link.setAttribute('download', fileDownloadName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error:', error);
    }
};

const UserReportButton = () => (
    <Button
        onClick={downloadUserReport}
        size="small"
    >
        Informe
    </Button>
);